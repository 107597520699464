import { Injectable } from '@angular/core';
import { Profile } from './profile';
import { THEME } from './theme';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  private _profile!: Profile;
  private _isAOCustomer: boolean = false;
  private _isTestable: boolean = false;

  get profile(): Profile {
    return this._profile;
  }

  set profile(profile: Profile) {
    this._profile = profile;
  }

  get isAOCustomer(): boolean {
    return this._isAOCustomer;
  }

  set isAOCustomer(isAOCustomer: boolean) {
    this._isAOCustomer = isAOCustomer;
  }

  get isTestable(): boolean {
    return this._isTestable;
  }

  set isTestable(isTestable: boolean) {
    this._isTestable = isTestable;
  }

  hasDarkColorScheme(): boolean {
    return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  }

  initTheme(theme: string): void {
    const hasDarkColorScheme = this.hasDarkColorScheme();
    if (hasDarkColorScheme || theme === THEME.dark) {
      this.changeTheme(THEME.light, THEME.dark);
    } else {
      this.changeTheme(THEME.dark, THEME.light);
    }
  }

  getTheme(): string {
    const hasDarkColorScheme = this.hasDarkColorScheme();
    if (hasDarkColorScheme) {
      return THEME.dark;
    } else {
      return THEME.light;
    }
  }

  isDarkMode(theme: string): boolean {
    let isDark = false;
    if (theme === THEME.dark) {
      isDark = true;
    }
    return isDark;
  }

  changeThemeToDark(): void {
    this.changeTheme(THEME.light, THEME.dark);
  }

  changeThemeToLight(): void {
    this.changeTheme(THEME.dark, THEME.light);
  }

  private changeTheme(oldTheme: string, newTheme: string): void {
    const body = document.body;
    if (body) {
      const parent = body.parentElement;
      if (parent) {
        parent.classList.remove(oldTheme);
        parent.classList.add(newTheme);
      }
    }
  }
}
