import { Injectable } from '@angular/core';
import { WootricOptions } from './wootric-options';
import { Profile } from '../../common/core/auth/profile';
import { PROFILE_ROLE } from '../../common/core/auth/profile-role';

@Injectable({
  providedIn: 'root'
})
export class WootricHelperService {

  getWorker(): Worker {
    return new Worker(new URL('wootric.worker', import.meta.url));
  }

  getOptions(profile: Profile, data: any[], msgText: string, msgLink: string): WootricOptions {
    const context = data[0][0];
    const arr: any[] = data[1].content;
    let apps: string[] = [];
    for (const app of arr) {
      apps.push(app.name);
    }
    const applications = [...apps].sort();
    const language = profile.locale.split('-')[0];
    const roles = profile.roles;
    let options: WootricOptions = {} as WootricOptions;

    if (roles.includes(PROFILE_ROLE.subscriberAdministrator)) {
      options = {
        email: profile.email,
        created_at: profile.created_at,
        account_token: 'NPS-EU-7fc8dc2f',
        language: language,
        product_name: '',
        disclaimer: {
          text: msgText,
          link: 'https://www.visma.com/privacy/customer-feedback/',
          link_word: msgLink
        },
        properties: {
          distributor: context.business_unit_name,
          customerID: context.external_id,
          customerName: context.organization_name,
          customerType: this.getCustomerType(data),
          applications: applications.join(' | '),
          summary: this.getSummary(data),
          feature: 'New Customer',
          role: PROFILE_ROLE.subscriberAdministrator
        },
        paths: []
      };
    }
    return options;
  }

  

  private getSummaryChunk(prop: number, msgpl: string, msgsg: string): string {
    let summary = '';
    const separator = ' | ';
    const space = ' ';
    if (prop > 0) {
      summary = summary.concat(String(prop));
      if (prop > 1) {
        summary = summary.concat(space, msgpl, separator);
      } else {
        summary = summary.concat(space, msgsg, separator);
      }
    }
    return summary;
  }

  private getSummary(data: any[]): string {
    const numberOfUsers = data[2].totalNumberOfRecords;
    const numberOfCompanies = data[3].totalNumberOfRecords;
    const numberOfUserGroups = data[4].totalNumberOfRecords;
    const numberOfCompanyGroups = data[5].totalNumberOfRecords;
    const numberOfRoleGroups = data[6].totalNumberOfRecords;
    let summary = '';
    summary = summary.concat(this.getSummaryChunk(numberOfCompanies, 'companies', 'company'));
    summary = summary.concat(this.getSummaryChunk(numberOfCompanyGroups, 'company groups', 'company group'));
    summary = summary.concat(this.getSummaryChunk(numberOfRoleGroups, 'role groups', 'role group'));
    summary = summary.concat(this.getSummaryChunk(numberOfUserGroups, 'user groups', 'user group'));
    summary = summary.concat(this.getSummaryChunk(numberOfUsers, 'users', 'user'));
    const index = summary.lastIndexOf(' | ');
    if (index === summary.length - 3) {
      summary = summary.substring(0, index);
    }
    return summary;
  }

  private getCustomerType(data: any[]): string {
    let customerType = '';
    const numberOfUsers = data[2].totalNumberOfRecords;
    const numberOfCompanies = data[3].totalNumberOfRecords;

    if (numberOfCompanies == 1 && numberOfUsers <= 20) {
      customerType = 'Small customer';
    } else if (numberOfCompanies == 1 && numberOfUsers > 20) {
      customerType = 'Big customer';
    } else if (numberOfCompanies >= 2 && numberOfCompanies <= 5) {
      customerType = 'Big customer';
    } else if (numberOfCompanies > 5) {
      customerType = 'Accounting Office';
    }
    return customerType;
  }
}
