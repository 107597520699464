import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AOSetup } from './ao-setup';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor(private httpClient: HttpClient) { }

  hasAOSetup(): Observable<AOSetup> {
    const url = '/v1/customers/aosetup';
    return this.httpClient.get<AOSetup>(url);
  }
  
}
